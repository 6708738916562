<template>
  <v-progress-linear :indeterminate="true" />
</template>

<script>
// import { createStripeInvoiceCustomer, createStripeInvoiceSubscription, createStripeInvoice } from '@/helpers/firebase-functions'

export default {
  async mounted () {
    // const { data: customer } = await createStripeInvoiceCustomer()
    // console.log(JSON.stringify(customer, null, 2))
    // const subscription = await createStripeInvoiceSubscription(customer.id)
    // console.log(JSON.stringify(subscription, null, 2))
    // const { data: invoice } = await createStripeInvoice(customer.id)
    // console.log(invoice)
    this.$router.replace({ name: 'second-home' })
  }
}
</script>
